// lib/axiosInstance.ts
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HOSTNAME, // Replace with your API base URL
  timeout: 30000, // Optional: Set a timeout for requests
});

// Store pending requests
const pendingRequests = new Map();

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Generate a unique key for the request
    const requestKey = `${config.method} ${config.url}`;

    // If the request is already pending, cancel it
    if (pendingRequests.has(requestKey)) {
      const cancelToken = pendingRequests.get(requestKey);
      if (cancelToken) {
        cancelToken('Duplicate request detected');
      }
    }

    // Create a new CancelToken for the current request
    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequests.set(requestKey, cancel);
    });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const requestKey = `${response.config.method} ${response.config.url}`;
    // Remove the request from the pending requests map
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
